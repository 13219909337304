@import '../../utils/fonts.css';
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 482px;
  padding: 60px 52px;
  background: #0F37A0;
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99999999999;
  animation: modal_appearence 0.25s forwards;
}
.modal-title {
  color: #FFFCF8;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "KiyosunaSans-Bold";
}
@media (max-width: 819px) {
  .modal-title {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .modal-title {
    font-size: 12px;
  }
}
.modal-data {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-top: 86px;
  width: 100%;
}
.modal-data-label {
  position: relative;
}
.modal-data-input {
  width: 100%;
  padding: 10px 0;
  background: transparent;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #5B74B4;
  color: #FFFCF8;
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .modal-data-input {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .modal-data-input {
    font-size: 12px;
  }
}
.modal-data-input::-moz-placeholder {
  color: #5B74B4;
}
.modal-data-input::placeholder {
  color: #5B74B4;
}
.modal-data-textarea {
  resize: vertical;
  max-height: 200px;
  min-height: 50px;
  padding-right: 10px;
}
.modal-data-validationMessage {
  position: absolute;
  bottom: -25px;
  color: #ec7474;
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .modal-data-validationMessage {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .modal-data-validationMessage {
    font-size: 12px;
  }
}
.modal-button {
  margin-top: 97px;
  width: 100%;
}
.modal-close {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 72px;
  height: 72px;
  stroke: #FFFCF8;
}
.modal-close:hover {
  stroke: #5B74B4;
}

@keyframes modal_appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 485px) {
  .modal {
    width: 100%;
    height: 100%;
  }
  .modal-title {
    font-size: 45px;
    font-family: "KiyosunaSans-Bold";
  }
}
@media (max-width: 485px) and (max-width: 819px) {
  .modal-title {
    font-size: 30px;
  }
}
@media (max-width: 485px) and (max-width: 485px) {
  .modal-title {
    font-size: 30px;
  }
}
@media (max-width: 485px) {
  .modal-button {
    margin-top: 50px;
  }
  .modal-close {
    display: block;
  }
}