@import './utils/fonts.css';
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  margin: 0;
  padding: 0;
}

@-moz-document url-prefix() {
  html {
    position: relative;
  }
}

body {
  font-display: swap;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-family: "Roboto";
  line-height: 1.5;
  background: #FFFCF8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

button {
  cursor: pointer;
  border: none;
}

.body-lock {
  position: fixed;
  overflow-y: scroll;
}