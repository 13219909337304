@import '../../utils/fonts.css';
.h1-desktop {
  color: #252525;
  font-size: 45px;
  font-family: "KiyosunaSans-Bold";
}
@media (max-width: 819px) {
  .h1-desktop {
    font-size: 30px;
  }
}
@media (max-width: 485px) {
  .h1-desktop {
    font-size: 30px;
  }
}