@import '../../utils/fonts.css';
.bar {
  z-index: 100;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: inset 0px 0px 50px 100px #FFFCF8;
  padding: 50px 0px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  background: transparent;
  gap: 20px;
  padding: 0 40px;
}

.menu {
  display: flex;
  align-items: center;
  gap: 30px;
}
.menu .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu .logo-divider {
  margin: 4px 0px 12px;
  height: 0.5px;
  width: 100%;
  background: #252525;
}
.menu-popup {
  position: absolute;
  top: 70px;
  right: 40px;
  padding: 20px 0px;
  background: #0F37A0;
  box-shadow: 0px 5px 10px 3px rgba(37, 37, 37, 0.25);
}
.menu-popup-links {
  display: flex;
  flex-direction: column;
}
.menu-popup .link {
  color: #FFFCF8;
  padding: 10px 50px;
  background: transparent;
  transition: 0.1s ease-in-out;
}
.menu-popup .link:hover {
  background: #5B74B4;
}

.links {
  display: flex;
  justify-content: center;
  -moz-column-gap: 16px;
       column-gap: 16px;
}
.links-burger {
  width: 29px;
  height: 6px;
  stroke: #252525;
  transition: stroke 0.2s ease-in-out;
}
.links-burger__close {
  width: 24px;
  height: 24px;
}
.links-burger-container {
  height: 29px;
  cursor: pointer;
}
.links-burger-container:hover .links-burger {
  stroke: #5B74B4;
}

.link {
  color: #252525;
  text-decoration: none;
  text-decoration-style: none;
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
  text-transform: uppercase;
}
@media (max-width: 819px) {
  .link {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .link {
    font-size: 12px;
  }
}

.select-language :global .ant-select-selector {
  background: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
}
.select-language :global .ant-select-selector .ant-select-selection-item {
  line-height: 15px !important;
}
.select-language-popup :global .ant-select-item-option-selected {
  background-color: #0F37A0 !important;
  color: #FFFCF8 !important;
}
.select-language-popup :global .ant-select-item-option-content {
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}

.flag {
  background: rgba(15, 55, 160, 0.2);
  display: inline-flex;
  align-self: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  outline: 0.5px solid rgba(15, 55, 160, 0.2);
}

@media (max-width: 819px) {
  .bar .menu .logo-divider {
    margin: 3px 0px 8px;
  }
  .bar .menu .logo-title {
    width: 140px;
  }
  .bar .menu .logo-subtitle {
    width: 92px;
  }
}
@media (max-width: 485px) {
  .bar {
    padding: 25px 0px;
  }
  .bar .menu .logo-divider {
    margin: 2px 0px 5px;
  }
  .bar .menu .logo-title {
    width: 88px;
  }
  .bar .menu .logo-subtitle {
    width: 58px;
  }
}