.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content {
  position: relative;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  padding: 0px;
}