@import '../../utils/fonts.css';
.section {
  box-shadow: 0px 0px 50px 50px #fffcf8;
  background: #FFFCF8;
}

.title {
  text-transform: uppercase;
}

.firstScreen {
  z-index: 1;
  position: sticky;
  inset: 155px;
  display: grid;
  margin: 155px 40px 50px 40px;
  grid-template-columns: 500px 300px 257px 183px;
  -moz-column-gap: 40px;
       column-gap: 40px;
  row-gap: 0px;
  grid-template-areas: "photo name name name" "photo . description description" "photo callToAction callToAction callToAction" "photo . . subImage";
}
.firstScreen-photo {
  grid-area: photo;
  height: 692px;
}
.firstScreen-photo-img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 692px;
  width: 500px;
}
.firstScreen-name {
  grid-area: name;
  color: #252525;
}
.firstScreen-name-title {
  font-size: 45px;
  font-family: "KiyosunaSans-Bold";
  text-transform: uppercase;
}
@media (max-width: 819px) {
  .firstScreen-name-title {
    font-size: 30px;
  }
}
@media (max-width: 485px) {
  .firstScreen-name-title {
    font-size: 30px;
  }
}
.firstScreen-name-subTitle {
  margin-top: 10px;
  font-size: 34px;
  font-family: "KiyosunaSans-Light";
  text-transform: uppercase;
}
@media (max-width: 819px) {
  .firstScreen-name-subTitle {
    font-size: 16px;
  }
}
@media (max-width: 485px) {
  .firstScreen-name-subTitle {
    font-size: 12px;
    font-family: "KiyosunaSans-Light";
  }
}
.firstScreen-name-divider {
  margin-top: 14px;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #0F37A0, #DD7700);
}
.firstScreen-description {
  grid-area: description;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  row-gap: 20px;
  color: #252525;
}
.firstScreen-description-item {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  -moz-column-gap: 12px;
       column-gap: 12px;
}
.firstScreen-description-item-icon {
  margin-top: 5px;
  width: 14px;
  height: 14px;
}
.firstScreen-description-item-text {
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .firstScreen-description-item-text {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .firstScreen-description-item-text {
    font-size: 12px;
  }
}
.firstScreen-callToAction {
  grid-area: callToAction;
  margin-top: 85px;
  align-self: flex-end;
  color: #0F37A0;
}
.firstScreen-callToAction-title {
  font-size: 18px;
  font-family: "KiyosunaSans-Bold";
  text-transform: uppercase;
}
@media (max-width: 819px) {
  .firstScreen-callToAction-title {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .firstScreen-callToAction-title {
    font-size: 12px;
  }
}
.firstScreen-callToAction-button {
  margin-top: 12px;
}
.firstScreen-callToAction-button__animate {
  animation: firstScreen-button_animate 2s;
}
.firstScreen-subImage {
  grid-area: subImage;
  height: 183px;
  width: 183px;
}
.firstScreen-subImage-img {
  height: 183px;
  width: 183px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: -50px;
     object-position: -50px;
}

.about {
  z-index: 10;
  padding: 50px 40px 50px 40px;
  box-shadow: 0px 0px 50px 35px #fffcf8;
}
.about-divider {
  height: 1px;
  width: 100%;
  background: #ECE6DB;
}
.about-divider__down {
  margin-top: 30px;
}
.about-title {
  margin-bottom: 50px;
}
.about-content {
  margin-top: 40px;
  display: grid;
  grid-auto-flow: column;
  -moz-column-gap: 43px;
       column-gap: 43px;
}
.about-content-photo {
  position: sticky;
  top: 385px;
  width: 500px;
  height: 410px;
  overflow: hidden;
}
.about-content-photo__slideDown {
  animation: about-photo_slideDown 2s ease-in-out forwards;
}
.about-content-photo-img {
  width: 500px;
  height: 300px;
  zoom: 1.3;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: -115px -90px;
     object-position: -115px -90px;
}
.about-content-description {
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  row-gap: 30px;
  color: #252525;
}
.about-content-description-item {
  position: relative;
  display: flex;
  flex-direction: column;
}
.about-content-description-item-container {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  -moz-column-gap: 40px;
       column-gap: 40px;
}
.about-content-description-item-icon {
  position: absolute;
  margin-top: 5px;
  margin-left: 0px;
  width: 14px;
  height: 14px;
}
.about-content-description-item-icon__move {
  animation: about-description-icon_move 1.75s ease-in-out forwards;
}
.about-content-description-item-text {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-left: 54px;
  color: #ECE6DB;
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .about-content-description-item-text {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .about-content-description-item-text {
    font-size: 12px;
  }
}
.about-content-description-item-text-paragraph__bold {
  font-size: 17px;
  font-family: "KiyosunaSans-Bold";
}
@media (max-width: 819px) {
  .about-content-description-item-text-paragraph__bold {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .about-content-description-item-text-paragraph__bold {
    font-size: 12px;
  }
}
.about-content-description-item-text__noAnimation {
  color: #252525;
}
.about-content-description-item-text__colorChange .about-content-description-item-text-paragraph__default {
  animation: about-description-text_colorChange 2s forwards;
}
.about-content-description-item-text__colorChange .about-content-description-item-text-paragraph__bold {
  animation: about-description-text_colorChange 1s forwards;
  animation-delay: 0.5s;
}
.about-content-description-item-divider {
  margin-top: 30px;
  width: 100%;
  height: 1px;
  background: #ECE6DB;
}
.about-content-description-item:last-child .about-content-description-item-divider {
  display: none;
}

.services {
  z-index: 10;
  padding: 50px 0px 50px 0px;
}
.services-title {
  margin: 0 40px;
}
.services-container {
  display: flex;
  flex-direction: column;
  margin: 50px 40px 0;
  row-gap: 46px;
}
.services-item {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 121px;
  grid-template-columns: 147px auto;
  align-items: center;
  -moz-column-gap: 33px;
       column-gap: 33px;
  padding-left: 40%;
  height: 121px;
  background: #F7F5F1;
}
.services-item-text {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .services-item-text {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .services-item-text {
    font-size: 12px;
  }
}
.services-item-text-paragraph__bold {
  font-size: 17px;
  font-family: "KiyosunaSans-Bold";
}
@media (max-width: 819px) {
  .services-item-text-paragraph__bold {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .services-item-text-paragraph__bold {
    font-size: 12px;
  }
}
.services-item-icon {
  width: 147px;
  height: 147px;
}
.services-item__animate {
  animation: services-item_animate 3s ease-in-out;
}
.services-item__animate .services-item-icon {
  animation: services-item-icon_animate 3s ease-in-out;
}
.services-request {
  display: grid;
  grid-template-columns: 35% 35% 30%;
  grid-template-areas: ". services-description services-description" "services-callToAction services-callToAction ." "services-button services-button services-button";
  padding: 50px 45px;
  background: #0F37A0;
}
.services-request-description {
  grid-area: services-description;
  color: rgba(255, 255, 255, 0.2);
}
.services-request-description-paragraph {
  font-size: 27px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .services-request-description-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 485px) {
  .services-request-description-paragraph {
    font-size: 12px;
  }
}
.services-request-description-paragraph__bold {
  font-size: 27px;
  font-family: "KiyosunaSans-Bold";
}
@media (max-width: 819px) {
  .services-request-description-paragraph__bold {
    font-size: 16px;
  }
}
@media (max-width: 485px) {
  .services-request-description-paragraph__bold {
    font-size: 12px;
  }
}
.services-request-description-paragraph__colorChange {
  animation: services-item-paragraph_colorChange 1s ease-in-out forwards;
}
.services-request-callToAction {
  grid-area: services-callToAction;
  display: grid;
  grid-template-columns: 58px auto;
  align-items: center;
  -moz-column-gap: 25px;
       column-gap: 25px;
  margin-top: 70px;
}
.services-request-callToAction-icon {
  display: flex;
  align-self: flex-start;
  width: 58px;
  height: 58px;
}
.services-request-callToAction-icon__move {
  animation: services-request-callToAction-icon_move 1.5s ease-in-out;
}
.services-request-callToAction-paragraph {
  color: #FFFCF8;
  font-size: 27px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .services-request-callToAction-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 485px) {
  .services-request-callToAction-paragraph {
    font-size: 12px;
  }
}
.services-request-button {
  grid-area: services-button;
  text-wrap: nowrap;
  margin-top: 30px;
  width: 380px;
  justify-self: flex-end !important;
}
.services-request-button:hover {
  width: 100%;
}
.services-request-button__hover {
  animation: services-request-button_hover 1.5s ease-in-out;
}

.contact {
  z-index: 10;
  padding: 50px 40px;
  box-shadow: none;
  display: grid;
  grid-template-columns: 300px auto;
  -moz-column-gap: 100px;
       column-gap: 100px;
  grid-template-areas: "contact-title contact-map" "contact-logo contact-map" "contact-description contact-map";
}
.contact-title {
  grid-area: contact-title;
}
.contact-logo {
  grid-area: contact-logo;
  width: 173px;
}
.contact-logo-container {
  width: 173px;
  margin-top: 80px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-logo-sub {
  width: 113px;
}
.contact-logo-divider {
  margin: 4px 0px 12px;
  height: 0.5px;
  width: 100%;
  background: #252525;
}
.contact-description {
  grid-area: contact-description;
}
.contact-description-container {
  margin-top: 40px;
  display: grid;
  grid-auto-flow: dense;
  row-gap: 30px;
}
.contact-description-item {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.contact-description-item-title {
  color: #0F37A0;
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .contact-description-item-title {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .contact-description-item-title {
    font-size: 12px;
  }
}
.contact-description-item-content {
  color: #252525;
  font-size: 17px;
  font-family: "KiyosunaSans-Light";
}
@media (max-width: 819px) {
  .contact-description-item-content {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .contact-description-item-content {
    font-size: 12px;
  }
}
.contact-map {
  grid-area: contact-map;
}
.contact-map-source {
  height: 100%;
}

.toTheTop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.toTheTop-container {
  cursor: pointer;
  display: inline-flex;
  padding: 2px 10px;
  background: #0F37A0;
  border-radius: 50%;
}
.toTheTop-icon {
  width: 21px;
  height: 37px;
}

@keyframes firstScreen-button_animate {
  50% {
    padding: 7px 150px;
  }
  100% {
    padding: 7px 30px;
  }
}
@keyframes firstScreen_fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@media (prefers-reduced-motion: no-preference) {
  @supports (animation-timeline: scroll()) {
    .firstScreen {
      animation: firstScreen_fade ease;
      animation-timeline: scroll();
      animation-range: 0vh 100vh;
    }
  }
}
@keyframes about-photo_slideDown {
  to {
    top: 590px;
  }
}
@keyframes about-description-text_colorChange {
  to {
    color: #252525;
  }
}
@keyframes about-description-icon_move {
  0% {
    left: 0px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 0px;
  }
}
@keyframes services-item_animate {
  50% {
    background: #0F37A0;
    color: #FFFCF8;
  }
  100% {
    background: #F7F5F1;
  }
}
@keyframes services-item-icon_animate {
  50% {
    width: 167px;
    height: 167px;
  }
  100% {
    width: 147px;
    height: 147px;
  }
}
@keyframes services-item-paragraph_colorChange {
  to {
    color: #FFFCF8;
  }
}
@keyframes services-request-callToAction-icon_move {
  50% {
    margin-left: 15px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes services-request-button_hover {
  50% {
    width: 100%;
  }
  100% {
    width: 380px;
  }
}
@media (max-width: 819px) {
  .firstScreen {
    z-index: 1;
    position: sticky;
    inset: 155px;
    display: grid;
    margin: 155px 40px 30px 40px;
    grid-template-columns: 28% 60%;
    -moz-column-gap: 12%;
         column-gap: 12%;
    row-gap: 0px;
    grid-template-areas: "photo photo" "name name" ". description" "subImage callToAction";
  }
  .firstScreen-photo {
    grid-area: photo;
    height: 298px;
  }
  .firstScreen-photo-img {
    -o-object-fit: cover;
       object-fit: cover;
    height: 298px;
    width: 100%;
  }
  .firstScreen-name {
    grid-area: name;
    margin-top: 30px;
  }
  .firstScreen-description {
    grid-area: description;
    margin-top: 20px;
  }
  .firstScreen-callToAction {
    grid-area: callToAction;
    margin-top: 85px;
  }
  .firstScreen-callToAction-button {
    width: 100%;
  }
  .firstScreen-callToAction-button:hover {
    padding: 7px 30px !important;
  }
  .firstScreen-callToAction-button__animate {
    animation: none;
  }
  .firstScreen-subImage {
    grid-area: subImage;
    margin-top: 40px;
    height: 114px;
    width: 207px;
  }
  .firstScreen-subImage-img {
    height: 114px;
    width: 207px;
    -o-object-position: 0px;
       object-position: 0px;
  }
  .about {
    z-index: 10;
    padding: 15px 40px 15px 40px;
    box-shadow: 0px 0px 50px 5px #fffcf8 !important;
  }
  .about-divider {
    height: 1px;
    width: 100%;
    background: #ECE6DB;
  }
  .about-divider__down {
    margin-top: 15px;
  }
  .about-title {
    margin-bottom: 30px;
  }
  .about-content {
    margin-top: 15px;
    display: grid;
    grid-auto-flow: dense;
    -moz-column-gap: 43px;
         column-gap: 43px;
  }
  .about-content-photo {
    position: static;
    width: 100%;
  }
  .about-content-photo-img {
    width: 100%;
    height: 298px;
    zoom: 1.8;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 0px -80px;
       object-position: 0px -80px;
  }
  .about-content-description {
    margin-top: 36px;
  }
  .services {
    z-index: 10;
    padding: 30px 0px 15px 0px;
    box-shadow: none;
  }
  .services-title {
    margin: 0 40px;
  }
  .services-container {
    display: flex;
    flex-direction: column;
    margin: 30px 40px 0;
    row-gap: 30px;
  }
  .services-container:last-child {
    margin: 30px 0 0;
  }
  .services-item {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 121px;
    grid-template-columns: 147px auto;
    align-items: center;
    -moz-column-gap: 52px;
         column-gap: 52px;
    padding: 0px 75px 0px 15%;
    height: 121px;
    background: #F7F5F1;
  }
  .services-item-icon {
    width: 130px;
    height: 130px;
  }
  .services-request {
    display: grid;
    grid-template-columns: 35% 40% 25%;
    grid-template-areas: ". services-description services-description" "services-callToAction services-callToAction ." "services-button services-button services-button";
    padding: 30px 40px;
    background: #0F37A0;
  }
  .services-request-description {
    grid-area: services-description;
    color: rgba(255, 255, 255, 0.2);
  }
  .services-request-description-paragraph {
    font-size: 27px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 819px) and (max-width: 819px) {
  .services-request-description-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 819px) and (max-width: 485px) {
  .services-request-description-paragraph {
    font-size: 12px;
  }
}
@media (max-width: 819px) {
  .services-request-description-paragraph__bold {
    font-size: 27px;
    font-family: "KiyosunaSans-Bold";
  }
}
@media (max-width: 819px) and (max-width: 819px) {
  .services-request-description-paragraph__bold {
    font-size: 16px;
  }
}
@media (max-width: 819px) and (max-width: 485px) {
  .services-request-description-paragraph__bold {
    font-size: 12px;
  }
}
@media (max-width: 819px) {
  .services-request-description-paragraph__colorChange {
    animation: services-item-paragraph_colorChange 1s ease-in-out forwards;
  }
  .services-request-callToAction {
    grid-area: services-callToAction;
    display: grid;
    grid-template-columns: 58px auto;
    align-items: center;
    -moz-column-gap: 25px;
         column-gap: 25px;
    margin-top: 60px;
  }
  .services-request-callToAction-icon {
    height: 56px;
    width: 56px;
  }
  .services-request-callToAction-icon__move {
    animation: services-request-callToAction-icon_move 1.5s ease-in-out;
  }
  .services-request-callToAction-paragraph {
    color: #FFFCF8;
    font-size: 27px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 819px) and (max-width: 819px) {
  .services-request-callToAction-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 819px) and (max-width: 485px) {
  .services-request-callToAction-paragraph {
    font-size: 12px;
  }
}
@media (max-width: 819px) {
  .services-request-button {
    grid-area: services-button;
    text-wrap: nowrap;
    margin-top: 30px;
    width: 380px;
    justify-self: flex-end !important;
  }
  .services-request-button:hover {
    width: 100%;
  }
  .services-request-button__hover {
    animation: services-request-button_hover 1.5s ease-in-out;
  }
  .contact {
    -moz-column-gap: 30px;
         column-gap: 30px;
  }
}
@media (max-width: 485px) {
  .firstScreen {
    z-index: 1;
    position: static;
    inset: 100px;
    display: grid;
    margin: 100px 40px 30px 40px;
    grid-template-columns: 24% 62%;
    -moz-column-gap: 14%;
         column-gap: 14%;
    row-gap: 0px;
    grid-template-areas: "photo photo" "name name" "description description" "subImage callToAction";
  }
  .firstScreen-photo {
    grid-area: photo;
    height: 200px;
  }
  .firstScreen-photo-img {
    -o-object-fit: cover;
       object-fit: cover;
    height: 200px;
    width: 100%;
  }
  .firstScreen-name {
    grid-area: name;
    margin-top: 40px;
  }
  .firstScreen-name-divider {
    display: none;
  }
  .firstScreen-description {
    grid-area: description;
    margin-top: 20px;
  }
  .firstScreen-description-item {
    -moz-column-gap: 7px;
         column-gap: 7px;
  }
  .firstScreen-description-item-icon {
    width: 10px;
    height: 10px;
  }
  .firstScreen-callToAction {
    grid-area: callToAction;
    margin-top: 45px;
  }
  .firstScreen-callToAction-button {
    width: 100%;
  }
  .firstScreen-callToAction-button:hover {
    padding: 7px 30px !important;
  }
  .firstScreen-callToAction-button__animate {
    animation: none;
  }
  .firstScreen-subImage {
    grid-area: subImage;
    margin-top: 40px;
    height: 81px;
    width: 81px;
  }
  .firstScreen-subImage-img {
    height: 81px;
    width: 81px;
    -o-object-position: -25px;
       object-position: -25px;
  }
  .about-content {
    margin-top: 15px;
    display: grid;
    grid-auto-flow: dense;
    -moz-column-gap: 43px;
         column-gap: 43px;
  }
  .about-content-photo {
    position: static;
    height: 245px;
    width: 100%;
  }
  .about-content-photo-img {
    width: 100%;
    height: 170px;
    zoom: 1;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 0px -27px;
       object-position: 0px -27px;
  }
  .services-item {
    display: grid;
    grid-auto-flow: dense;
    grid-template-rows: auto;
    grid-template-columns: auto;
    align-items: center;
    row-gap: 20px;
    height: auto;
    background: #F7F5F1;
    padding: 20px;
  }
  .services-item__animate .services-item-icon {
    animation: none;
  }
  .services-item-icon {
    width: 64px;
    height: 64px;
  }
  .services-request {
    display: grid;
    grid-template-columns: 21px auto;
    -moz-column-gap: 30px;
         column-gap: 30px;
    grid-template-areas: "services-description services-description" "services-callToAction services-callToAction" ". services-button";
    grid-auto-flow: column;
    padding: 30px 50px 40px 45px;
    background: #0F37A0;
  }
  .services-request-description {
    grid-area: services-description;
    color: rgba(255, 255, 255, 0.2);
  }
  .services-request-description-paragraph {
    font-size: 27px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 485px) and (max-width: 819px) {
  .services-request-description-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 485px) and (max-width: 485px) {
  .services-request-description-paragraph {
    font-size: 12px;
  }
}
@media (max-width: 485px) {
  .services-request-description-paragraph__bold {
    font-size: 27px;
    font-family: "KiyosunaSans-Bold";
  }
}
@media (max-width: 485px) and (max-width: 819px) {
  .services-request-description-paragraph__bold {
    font-size: 16px;
  }
}
@media (max-width: 485px) and (max-width: 485px) {
  .services-request-description-paragraph__bold {
    font-size: 12px;
  }
}
@media (max-width: 485px) {
  .services-request-description-paragraph__colorChange {
    animation: services-item-paragraph_colorChange 1s ease-in-out forwards;
  }
  .services-request-callToAction {
    grid-area: services-callToAction;
    display: grid;
    grid-template-columns: 21px auto;
    align-items: center;
    -moz-column-gap: 30px;
         column-gap: 30px;
    margin-top: 40px;
  }
  .services-request-callToAction-icon {
    margin-top: 3px;
    height: 21px;
    width: 21px;
  }
  .services-request-callToAction-icon__move {
    animation: services-request-callToAction-icon_move 1.5s ease-in-out;
  }
  .services-request-callToAction-paragraph {
    color: #FFFCF8;
    font-size: 27px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 485px) and (max-width: 819px) {
  .services-request-callToAction-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 485px) and (max-width: 485px) {
  .services-request-callToAction-paragraph {
    font-size: 12px;
  }
}
@media (max-width: 485px) {
  .services-request-button {
    grid-area: services-button;
    text-wrap: nowrap;
    margin-top: 20px;
    width: 100%;
    padding: 8.5px 0px !important;
    justify-self: flex-end !important;
  }
  .services-request-button:hover {
    width: 100%;
  }
  .services-request-button__hover {
    animation: none;
  }
  .contact {
    z-index: 10;
    padding: 50px 40px;
    box-shadow: none;
    display: grid;
    grid-template-columns: auto;
    grid-template-areas: "contact-title" "contact-description" "contact-map" "contact-logo";
  }
  .contact-logo {
    width: 173px;
  }
  .contact-logo-container {
    margin-top: 30px;
    display: inline-flex;
    justify-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-logo-sub {
    width: 113px;
  }
  .contact-logo-divider {
    margin: 4px 0px 12px;
    height: 0.5px;
    width: 100%;
    background: #252525;
  }
  .contact-description-container {
    margin-top: 40px;
    display: grid;
    grid-auto-flow: dense;
    row-gap: 30px;
  }
  .contact-description-item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .contact-description-item-title {
    color: #0F37A0;
    font-size: 17px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 485px) and (max-width: 819px) {
  .contact-description-item-title {
    font-size: 15px;
  }
}
@media (max-width: 485px) and (max-width: 375px) {
  .contact-description-item-title {
    font-size: 12px;
  }
}
@media (max-width: 485px) {
  .contact-description-item-content {
    color: #252525;
    font-size: 17px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 485px) and (max-width: 819px) {
  .contact-description-item-content {
    font-size: 15px;
  }
}
@media (max-width: 485px) and (max-width: 375px) {
  .contact-description-item-content {
    font-size: 12px;
  }
}
@media (max-width: 485px) {
  .contact-map {
    margin-top: 40px;
  }
}
@media (max-width: 375px) {
  .firstScreen {
    z-index: 1;
    position: static;
    inset: 100px;
    display: grid;
    margin: 100px 40px 30px 40px;
    grid-template-columns: 24% 62%;
    -moz-column-gap: 14%;
         column-gap: 14%;
    row-gap: 0px;
    grid-template-areas: "photo photo" "name name" "description description" "subImage callToAction";
  }
  .firstScreen-photo {
    grid-area: photo;
    height: 200px;
  }
  .firstScreen-photo-img {
    -o-object-fit: cover;
       object-fit: cover;
    height: 200px;
    width: 100%;
  }
  .firstScreen-name {
    grid-area: name;
    margin-top: 40px;
  }
  .firstScreen-name-divider {
    display: none;
  }
  .firstScreen-description {
    grid-area: description;
    margin-top: 20px;
  }
  .firstScreen-description-item {
    -moz-column-gap: 7px;
         column-gap: 7px;
  }
  .firstScreen-description-item-icon {
    width: 10px;
    height: 10px;
  }
  .firstScreen-callToAction {
    grid-area: callToAction;
    margin-top: 45px;
  }
  .firstScreen-callToAction-button {
    width: 100%;
  }
  .firstScreen-callToAction-button:hover {
    padding: 7px 30px !important;
  }
  .firstScreen-callToAction-button__animate {
    animation: none;
  }
  .firstScreen-subImage {
    grid-area: subImage;
    margin-top: 40px;
    height: 81px;
    width: 81px;
  }
  .firstScreen-subImage-img {
    height: 81px;
    width: 81px;
    -o-object-position: -25px;
       object-position: -25px;
  }
  .about {
    z-index: 10;
    padding: 15px 40px 15px 40px;
    box-shadow: 0px 0px 50px 5px #fffcf8 !important;
  }
  .about-divider {
    height: 1px;
    width: 100%;
    background: #ECE6DB;
  }
  .about-divider__down {
    margin-top: 15px;
  }
  .about-title {
    margin-bottom: 30px;
  }
  .about-content {
    margin-top: 15px;
    display: grid;
    grid-auto-flow: dense;
    -moz-column-gap: 43px;
         column-gap: 43px;
  }
  .about-content-photo {
    position: static;
    height: 245px;
    width: 100%;
  }
  .about-content-photo-img {
    width: 100%;
    height: 170px;
    zoom: 1;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 0px -27px;
       object-position: 0px -27px;
  }
  .about-content-description {
    margin-top: 36px;
  }
  .about-content-description-item-container {
    -moz-column-gap: 10px;
         column-gap: 10px;
  }
  .about-content-description-item-icon {
    width: 10px;
    height: 10px;
  }
  .about-content-description-item-icon__move {
    animation: none;
  }
  .about-content-description-item-text {
    margin-left: 20px;
  }
  .services {
    z-index: 10;
    padding: 40px 0px 15px 0px;
    box-shadow: none;
  }
  .services-title {
    margin: 0 40px;
  }
  .services-container {
    display: flex;
    flex-direction: column;
    margin: 20px 40px 0;
    row-gap: 30px;
  }
  .services-container:last-child {
    margin: 30px 0 0;
  }
  .services-item {
    display: grid;
    grid-auto-flow: dense;
    grid-template-rows: auto;
    grid-template-columns: auto;
    align-items: center;
    row-gap: 20px;
    height: auto;
    background: #F7F5F1;
    padding: 20px;
  }
  .services-item__animate .services-item-icon {
    animation: none;
  }
  .services-item-icon {
    width: 64px;
    height: 64px;
  }
  .services-request {
    display: grid;
    grid-template-columns: 21px auto;
    -moz-column-gap: 30px;
         column-gap: 30px;
    grid-template-areas: "services-description services-description" "services-callToAction services-callToAction" ". services-button";
    grid-auto-flow: column;
    padding: 30px 50px 40px 45px;
    background: #0F37A0;
  }
  .services-request-description {
    grid-area: services-description;
    color: rgba(255, 255, 255, 0.2);
  }
  .services-request-description-paragraph {
    font-size: 27px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 375px) and (max-width: 819px) {
  .services-request-description-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 375px) and (max-width: 485px) {
  .services-request-description-paragraph {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .services-request-description-paragraph__bold {
    font-size: 27px;
    font-family: "KiyosunaSans-Bold";
  }
}
@media (max-width: 375px) and (max-width: 819px) {
  .services-request-description-paragraph__bold {
    font-size: 16px;
  }
}
@media (max-width: 375px) and (max-width: 485px) {
  .services-request-description-paragraph__bold {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .services-request-description-paragraph__colorChange {
    animation: services-item-paragraph_colorChange 1s ease-in-out forwards;
  }
  .services-request-callToAction {
    grid-area: services-callToAction;
    display: grid;
    grid-template-columns: 21px auto;
    align-items: center;
    -moz-column-gap: 30px;
         column-gap: 30px;
    margin-top: 40px;
  }
  .services-request-callToAction-icon {
    margin-top: 3px;
    height: 21px;
    width: 21px;
  }
  .services-request-callToAction-icon__move {
    animation: services-request-callToAction-icon_move 1.5s ease-in-out;
  }
  .services-request-callToAction-paragraph {
    color: #FFFCF8;
    font-size: 27px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 375px) and (max-width: 819px) {
  .services-request-callToAction-paragraph {
    font-size: 16px;
  }
}
@media (max-width: 375px) and (max-width: 485px) {
  .services-request-callToAction-paragraph {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .services-request-button {
    grid-area: services-button;
    text-wrap: nowrap;
    margin-top: 20px;
    width: 100%;
    padding: 8.5px 0px !important;
    justify-self: flex-end !important;
  }
  .services-request-button:hover {
    width: 100%;
  }
  .services-request-button__hover {
    animation: none;
  }
  .contact {
    z-index: 10;
    padding: 50px 40px;
    box-shadow: none;
    display: grid;
    grid-template-columns: auto;
    grid-template-areas: "contact-title" "contact-description" "contact-map" "contact-logo";
  }
  .contact-logo {
    width: 173px;
  }
  .contact-logo-container {
    margin-top: 30px;
    display: inline-flex;
    justify-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-logo-sub {
    width: 113px;
  }
  .contact-logo-divider {
    margin: 4px 0px 12px;
    height: 0.5px;
    width: 100%;
    background: #252525;
  }
  .contact-description-container {
    margin-top: 40px;
    display: grid;
    grid-auto-flow: dense;
    row-gap: 30px;
  }
  .contact-description-item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .contact-description-item-title {
    color: #0F37A0;
    font-size: 17px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 375px) and (max-width: 819px) {
  .contact-description-item-title {
    font-size: 15px;
  }
}
@media (max-width: 375px) and (max-width: 375px) {
  .contact-description-item-title {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .contact-description-item-content {
    color: #252525;
    font-size: 17px;
    font-family: "KiyosunaSans-Light";
  }
}
@media (max-width: 375px) and (max-width: 819px) {
  .contact-description-item-content {
    font-size: 15px;
  }
}
@media (max-width: 375px) and (max-width: 375px) {
  .contact-description-item-content {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .contact-map {
    margin-top: 40px;
  }
}