@import '../../utils/fonts.css';
.button {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 7px 30px;
  background: #0F37A0;
  color: #FFFCF8;
  transition: 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "KiyosunaSans-Bold";
}
@media (max-width: 819px) {
  .button {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .button {
    font-size: 12px;
  }
}
@media (max-width: 485px) {
  .button {
    font-size: 27px;
    font-family: "KiyosunaSans-Bold";
  }
}
@media (max-width: 485px) and (max-width: 819px) {
  .button {
    font-size: 16px;
  }
}
@media (max-width: 485px) and (max-width: 485px) {
  .button {
    font-size: 12px;
  }
}
.button__primary {
  color: #FFFCF8;
  background: #0F37A0;
}
.button__primary:hover {
  padding: 7px 150px;
}
.button__primary:disabled {
  background: #5B74B4;
  pointer-events: none;
}
.button__secondary {
  color: #0F37A0;
  background: #FFFCF8;
  padding: 12px 90px;
  justify-content: center;
}
.button__secondary:hover {
  padding: 12px 210px;
}
.button__secondary:disabled {
  background: #ECE6DB;
  pointer-events: none;
}
.button__small {
  color: #0F37A0;
  background: #FFFCF8;
  padding: 8px 112px;
  font-size: 18px;
  font-family: "KiyosunaSans-Bold";
}
@media (max-width: 819px) {
  .button__small {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .button__small {
    font-size: 12px;
  }
}
.button__small:active {
  background: #5B74B4;
}
.button__small:disabled {
  color: #252525;
}